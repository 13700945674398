import { LatLng } from "leaflet";
import { useMap, useMapEvents } from "react-leaflet";
import { useHistory, useLocation } from "react-router-dom";

export function PersistMapPosition() {
  const queryParams = useLocation().search;
  const map = useMap();
  const history = useHistory();

  useMapEvents({
    moveend: () => {
      persistMapLocation(map.getCenter(), map.getZoom());
    },
  });

  function persistMapLocation(center: LatLng, zoom: number) {
    const params = new URLSearchParams(queryParams);

    params.set("lat", center.lat.toString());
    params.set("lon", center.lng.toString());
    params.set("zoom", zoom.toString());

    history.push({
      search: params.toString(),
    });
  }

  // render the component empty
  return null;
}
